import s from './Toasts.module.scss';
import { IconToastError, IconToastSuccess, IconToastClose } from "@/shared/ui/Icons";
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TOAST_TIMEOUT = 4000;
const ICONS: any = {
  success: <IconToastSuccess className={s.successIcon} />,
  error: <IconToastError className={s.errorIcon} />,
}

const Toasts = (props: any) => {

  return (
    <ToastContainer
      position="bottom-right"
      closeButton={({closeToast}) => {
        return <button className={s.closeButton} onClick={closeToast}><IconToastClose/></button>
      }}
      transition={Slide}
      className={s.root}
      closeOnClick={false}
      toastClassName={s.toastWrapper}
      bodyClassName={s.toastBody}
      autoClose={TOAST_TIMEOUT}
      draggable={false}
      hideProgressBar={true}
      icon={({type}) => {
        return ICONS[type];
      }}
      {...props}
    />
  );
}

export default Toasts;
