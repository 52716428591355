import Head from 'next/head'
import Header from '@/features/Header';
import MainLayout from '@/shared/layouts/MainLayout';
import Accounts from '@/features/Accounts';
import Confirmation from '@/features/Confirmation';
import ModalsManager from '@/features/ModalsManager';
import Toasts from "@/shared/ui/Toasts";

export default function Home() {
  return (
    <>
      <Head>
        <title>Hiveon Business Portal</title>
        <meta name="description" content="" />
      </Head>
      <Header/>
      <MainLayout>
        <Accounts />
        <Confirmation />
        <Toasts />
        <ModalsManager/>
      </MainLayout>
    </>
  )
}
