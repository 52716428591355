import _get from 'lodash/get';

import { WatcherLinkCreateModalController } from '@/features/WatcherLink';

const ModalsManager = () => {
  return (
    <WatcherLinkCreateModalController/>
  );
}

export default ModalsManager;
